<style>
  .logo {
    border-radius: 50%;
    animation: 2s glow infinite ease-in-out;
    animation-direction: alternate;
    display: block;
    margin: 1.5rem auto;
    margin-top: 3rem;
  }
</style>

<header>
  <img src="/logo.png" width="128" height="128" class="logo" alt="dark-firepit logo">
  <h1 class="center glow-text"><a class="stealth-link" href="/">dark-firepit</a></h1>
</header>